import { StrictMode } from 'react';
import theme from './theme';
import { Global, css, ThemeProvider } from '@emotion/react';
import { ScreenClassProvider, setConfiguration } from 'react-grid-system';
import grid from 'theme/grid';
import colors from 'theme/colors';
import greys from 'theme/greys';
import { SessionProvider } from 'contexts/sessionContext';
import { QueryClient, QueryClientProvider } from 'react-query';
import borderRadius from 'theme/borderRadius';
import 'react-datepicker/dist/react-datepicker.css';
import AppRoutes from './AppRoutes';
import 'i18n';
import { HeaderProvider } from 'contexts/headerContext';
import { ModalProvider } from 'react-modal-hook';
import { TransitionGroup } from 'react-transition-group';
import { SidebarSettingsProvider } from 'contexts/sidebarSettingsContext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

setConfiguration(grid);

const styles = css`
    @font-face {
        font-family: 'Basis Grotesque Pro Bold';
        src: url('../public/fonts/Basis-Grotesque-Pro/BasisGrotesquePro-Bold.woff2') format('woff2'),
            url('../public/fonts/Basis-Grotesque-Pro/BasisGrotesquePro-Bold.woff') format('woff'),
            url('../public/fonts/Basis-Grotesque-Pro/BasisGrotesquePro-Bold.ttf') format('truetype');
        font-weight: bold;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Basis Grotesque Pro Medium';
        src: url('../public/fonts/Basis-Grotesque-Pro/BasisGrotesquePro-Medium.woff2') format('woff2'),
            url('../public/fonts/Basis-Grotesque-Pro/BasisGrotesquePro-Medium.woff') format('woff'),
            url('../public/fonts/Basis-Grotesque-Pro/BasisGrotesquePro-Medium.ttf') format('truetype');
        font-weight: 500;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Basis Grotesque Pro Regular';
        src: url('../public/fonts/Basis-Grotesque-Pro/BasisGrotesquePro-Regular.woff2') format('woff2'),
            url('../public/fonts/Basis-Grotesque-Pro/BasisGrotesquePro-Regular.woff') format('woff'),
            url('../public/fonts/Basis-Grotesque-Pro/BasisGrotesquePro-Regular.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }

    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-smoothing: antialiased;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-family: 'Basis Grotesque Pro', sans-serif;
        &:before, :after {
            box-sizing: border-box;
            font-smoothing: antialiased;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }
    }

    h1 {
        font-size: 2.625rem;
        margin-bottom: 1rem;
        color: ${colors.white};
        font-family: 'Basis Grotesque Pro';
        font-weight: bold;
        line-height: 3.625rem;
        white-space: pre-line;
    }

    h2 {
        color: ${colors.black};
        font-size: 1.625rem;
        line-height: 3.625rem;
        font-family: 'Basis Grotesque Pro';
        font-weight: bold;
    }

    h3 {
        color: ${colors.black};
        font-size: 1.125rem;
        line-height: 1.625rem;
        margin-bottom: 1.75rem;
        font-family: 'Basis Grotesque Pro';
        font-weight: bold;
    }
    h4 {
        color: ${greys[900]};
        font-size: 0.875rem;
        line-height: 0.938rem;
        margin-bottom: 1.313rem;
        font-family: 'Basis Grotesque Pro';
        font-weight: 500;
    }
    p {
        font-size: 0.813rem;
        margin-bottom: 1rem;
    }

    @keyframes rotate {
        from {
            transform: rotate(0);
        }
        to {
            transform: rotate(360deg);
        }
    }

    .icon-wrapper {
        pointer-events: none;

        span {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .react-code-input {
        width: 100%;
        border-top: 1px solid ${greys[50]};
        border-left: 1px solid ${greys[50]};
        border-radius: ${borderRadius.normal}px;
        overflow: hidden;
    }

    .react-datepicker-wrapper,
    .react-datepicker__input-container,
    .react-datepicker__input-container input {
        display: block;
        width: 100%;
    }
    .react-loading-skeleton {
        z-index: 0!important;
    }
    .combo-box-popover {
        position: fixed;
        top: auto;
        z-index: 50;
        width: calc(100% - 58px);
    }
`;

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: false,
            refetchOnWindowFocus: false
        }
    }
});

const App = () => {
    return (
        <StrictMode>
            <Global styles={styles} />
            <ToastContainer />
            <ThemeProvider theme={theme}>
                <SidebarSettingsProvider>
                    <HeaderProvider>
                        <SessionProvider>
                            <ScreenClassProvider>
                                <ModalProvider rootComponent={TransitionGroup}>
                                    <QueryClientProvider client={queryClient}>
                                        <AppRoutes />
                                    </QueryClientProvider>
                                </ModalProvider>
                            </ScreenClassProvider>
                        </SessionProvider>
                    </HeaderProvider>
                </SidebarSettingsProvider>
            </ThemeProvider>
        </StrictMode>
    );
};

export default App;
