import { FC, useEffect, useState } from 'react';
import { Wrapper } from './styles';
import LoadingImage from 'static/icons/loading.svg';
import { useTranslation } from 'react-i18next';

export interface LoadingProps {
    visible?: boolean;
    fullScreen?: boolean;
}

const Loading: FC<LoadingProps> = ({ visible = true, fullScreen = true }) => {
    const { t } = useTranslation();
    const [takesLonger, setTakesLonger] = useState(false);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setTakesLonger(true);
        }, 5000);

        return () => clearTimeout(timeout);
    }, []);
    return (
        <Wrapper visible={visible} fullScreen={fullScreen}>
            <img alt="Loading" src={LoadingImage} />
            {takesLonger &&
                <p>{t('common:loadingTakesLonger')}</p>
            }
        </Wrapper>
    );
};

export default Loading;
