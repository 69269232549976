export interface Card {
    media: {
        height: {
            small: number;
        };
    };
    padding: number;
}

const card: Card = {
    media: {
        height: {
            small: 128
        }
    },
    padding: 24
};

export default card;
