export interface BorderRadius {
  small: number;
  normal: number;
  large: number;
}

const borderRadius: BorderRadius = {
  small: 4,
  normal: 6,
  large: 16,
};

export default borderRadius;
