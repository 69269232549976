export interface Greys {
    [key: number]: string;
}

const greys: Greys = {
    25: '#F4F7FC',
    50: '#EDF1F7',
    100: '#DFE5EF',
    300: '#CCD4DE',
    500: '#B1B7C1',
    700: '#7E838B',
    900: '#545556',
};

export default greys;
