import greys from './greys';

export interface Colors {
    primary: string;
    primaryDark: string;
    secondary: string;
    secondaryDark: string;
    success: string;
    error: string;
    black: string;
    white: string;
    grey: string;
    mediumGrey: string;
    dark: string;
    border: string;
}

const colors: Colors = {
    primary: '#92C530',
    primaryDark: '#506E1A',
    secondary: '#3086C5',
    secondaryDark: '#1B4A6B',
    error: '#fc6f52',
    success: '#4CAF50',
    black: '#000000',
    white: '#ffffff',
    grey: greys[50],
    mediumGrey: greys[300],
    dark: greys[700],
    border: '#E0DFDF',
};

export const colorsList = Object.entries(colors).map(c => c[1]);

export default colors;
