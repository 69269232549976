import colors from './colors';
import borderRadius from './borderRadius';
import navigation from './navigation';
import greys from './greys';
import header from './header';
import sidebar from './sidebar';
import card from './card';

const theme = {
    colors,
    greys,
    borderRadius,
    navigation,
    header,
    sidebar,
    card,
};

export default theme;
